/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import {
  FinishedWorkOrderLine,
  RelatedRequirement,
  WorkOrderLine,
} from '@tag/graphql';
import { Operation } from 'fast-json-patch';
import { deepClone } from 'fast-json-patch/module/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateExtendedPipe } from '@helpers/pipes/date-extended.pipe';
import { FinishedWorkOrderLinesGQL } from '@shared/apollo/queries/finished-work-order-line';
import { WorkOrderLinesGQL } from '@shared/apollo/queries/work-order-line';
import { GetWorkOrder } from '@stores-actions/work-order.action';
import { Paginated } from '@stores-models/paginated';
import {
  ReportMaintenance,
  ReportMaintenanceReturn,
  WorkOrderLineFeedback,
} from '@api/types';
import { ApiService } from '@services/api.service';

export interface BatchWolReturn {
  workOrderLines: WorkOrderLine[] &
    { usages: RelatedRequirement[]; feedbacks: WorkOrderLineFeedback }[];
}

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class WorkOrderLineStoreService {
  constructor(
    private workOrderLinesGQL: WorkOrderLinesGQL,
    private plannedWorkOrderLinesGQL: WorkOrderLinesGQL,
    private paginatedWorkOrderLinesGQL: WorkOrderLinesGQL,
    private api: ApiService,
    private paginatedFinishedWorkOrderLinesGQL: FinishedWorkOrderLinesGQL,
    private dateExtentedPipe: DateExtendedPipe
  ) {}

  /**
   * Fetchs work order lines
   *
   * @param filter OData filter
   *
   * @returns work order lines
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchWorkOrderLines(filter?: string): Observable<WorkOrderLine[]> {
    return this.workOrderLinesGQL
      .watch({ filter })
      .valueChanges.pipe(
        map((res) =>
          this.mapWorkOrderLineDtoToWorkOrderLine(res.data.workOrderLines.items)
        )
      );
  }

  /**
   * Fetchs planned work order lines
   *
   * @param filter OData filter
   *
   * @returns work order lines
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPlannedWorkOrderLines(filter?: string): Observable<WorkOrderLine[]> {
    return this.plannedWorkOrderLinesGQL
      .watch({ filter })
      .valueChanges.pipe(
        map((res) =>
          this.mapWorkOrderLineDtoToWorkOrderLine(res.data.workOrderLines.items)
        )
      );
  }

  /**
   * Fetchs request
   *
   * @param filter OData filter
   *
   * @returns request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPaginatedFinishedWorkOrderLines(
    top?: number,
    skip?: number,
    filter?: string,
    search?: string
  ): Observable<Paginated<FinishedWorkOrderLine>> {
    return this.paginatedFinishedWorkOrderLinesGQL
      .watch({ filter, top, skip, search })
      .valueChanges.pipe(
        map((result) => ({
          items: result.data.finishedWorkOrderLines.items,
          totalCount: result.data.finishedWorkOrderLines.totalCount,
          skip,
          top,
          filter,
          custom: 'finished',
        }))
      );
  }
  /**
   * Fetchs request
   *
   * @param filter OData filter
   *
   * @returns request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPaginatedPlannedWorkOrderLines(
    top?: number,
    skip?: number,
    filter?: string,
    search?: string
  ): Observable<Paginated<WorkOrderLine>> {
    return this.plannedWorkOrderLinesGQL
      .watch({ filter, top, skip, search })
      .valueChanges.pipe(
        map((result) => ({
          items: result.data.workOrderLines.items,
          totalCount: result.data.workOrderLines.totalCount,
          skip,
          top,
          filter,
          custom: 'planned',
        }))
      );
  }
  /**
   * Fetchs request
   *
   * @param filter OData filter
   *
   * @returns request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPaginatedWorkOrderLines(
    top?: number,
    skip?: number,
    filter?: string,
    search?: string
  ): Observable<Paginated<WorkOrderLine>> {
    return this.paginatedWorkOrderLinesGQL
      .watch({ filter, top, skip, search })
      .valueChanges.pipe(
        map((result) => ({
          items: result.data.workOrderLines.items,
          totalCount: result.data.workOrderLines.totalCount,
          skip,
          top,
          filter,
          custom: 'released',
        }))
      );
  }

  /**
   * Deletes work order line
   *
   * @param no
   * @param line
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteWorkOrderLine(no: string, line: number): Observable<any> {
    return this.api.delete(`/work-order-lines/Released-${no}-${line}`);
  }

  /**
   * Adds work order line
   *
   * @param payload
   *
   * @returns work order line
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addWorkOrderLine(payload: WorkOrderLine): Observable<WorkOrderLine> {
    throw new Error('Method not implemented.');
  }

  /**
   * Adds work order lines
   *
   * @param payload
   *
   * @returns work order line
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addWorkOrderLines(payload: any): Observable<BatchWolReturn> {
    return this.api.post('/work-order-lines', payload);
  }

  /**
   * Updates work order line
   *
   * @param no
   * @param line
   * @param patch
   *
   * @returns work order line
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateWorkOrderLine(
    no: string,
    line: number,
    patch: Operation[]
  ): Observable<WorkOrderLine> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates work order line
   *
   * @param payload Report maintenance object
   *
   * @returns work order line
   * @deprecated This is an internal implementation method, do not use directly.
   */
  reportWorkOrderLineMaintenance(
    payload: ReportMaintenance
  ): Observable<ReportMaintenanceReturn> {
    return this.api.post('/work-order-lines/report-maintenance', payload);
  }

  /**
   * Updates local stores (feedback and Usages) from combined Work Order Line Call (report maintenance or batch report)
   *
   * @param wol
   */
  updateLocalStoresFromCombinedCall(payload: BatchWolReturn): any[] {
    const storePayload: any[] = [];

    // Get unique list of WOl's
    // Update work orders flow fields from the update WOLs
    const workOrderNoList = payload.workOrderLines.map(
      (wol) => wol.workOrderNo
    );
    const uniqueWorkOrderNoList = [...new Set(workOrderNoList)];

    uniqueWorkOrderNoList.forEach((workOrderNo) => {
      storePayload.push(new GetWorkOrder(workOrderNo));
    });

    return storePayload;
  }

  cleanWorkOrderLine(workOrderLine: WorkOrderLine): WorkOrderLine {
    let obj: any = { ...workOrderLine };
    obj = Object.keys(obj)
      .filter((k) => obj[k] != null && obj[k] !== '')
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
    return obj;
  }

  mapWorkOrderLineDtoToWorkOrderLine(wols: WorkOrderLine[]): WorkOrderLine[] {
    return wols
      .filter((wol) => {
        if (!wol.workOrderNo) {
          console.warn('Invalid work Order Line', wol);
          return false;
        }
        return true;
      })
      .map(
        (wol): WorkOrderLine => ({
          ...wol,
          startingDatetime: this.bcDateParser(wol.startingDatetime),
          endingDatetime: this.bcDateParser(wol.endingDatetime),
          resultDatetime: this.bcDateParser(wol.resultDatetime),
          dueByDate: this.bcDateParser(wol.dueByDate),
        })
      );
  }

  updateWorkOrderLineByReportWOL(reportedWol: any, wol: WorkOrderLine): void {
    const rep = deepClone(reportedWol);
    delete rep.feedback;
    delete rep.usages;
    wol = { ...wol, ...rep };
  }

  private bcDateParser(date?: Date | string): Date | undefined {
    if (!date) return undefined;
    const rawDate = new Date(date);
    if (
      rawDate.getTime() < new Date('1970-01-01').getTime() ||
      date.toString() === '0001-01-01T00:00:00Z'
    )
      return undefined;
    return rawDate;
  }
}
