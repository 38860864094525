import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { config } from '@tag/graphql';
import type { GraphQLParams, WorkOrderLine } from '@tag/graphql';

interface Response {
  workOrderLines: {
    items: WorkOrderLine[];
    totalCount: number;
  };
}

interface PlannedResponse {
  plannedWorkOrderLines: {
    items: WorkOrderLine[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class WorkOrderLinesGQL extends Query<
  Response,
  GraphQLParams<'workOrderLine'>
> {
  document = gql(config.workOrderLine.query);
}

@Injectable({
  providedIn: 'root',
})
export class WorkOrderLinesCountGQL extends Query<
  Response & { workOrderLinesCount: number },
  GraphQLParams<'workOrderLine'>
> {
  document = gql(config.workOrderLine.query);
}

@Injectable({
  providedIn: 'root',
})
export class PlannedWorkOrderLinesGQL extends Query<
  PlannedResponse,
  GraphQLParams<'workOrderLine'>
> {
  document = gql(config.plannedWorkOrderLine.query);
}

@Injectable({
  providedIn: 'root',
})
export class PlannedWorkOrderLinesCountGQL extends Query<
  Response & { plannedWorkOrderLinesCount: number },
  GraphQLParams<'workOrderLine'>
> {
  document = gql(config.plannedWorkOrderLine.query);
}
