import { Injectable } from '@angular/core';
import {
  Break,
  Crew,
  CrewSchedule,
  Employee,
  Holiday,
  Personnel,
  TimesheetHeader,
  TimezoneOffset,
  WorkOrder,
  WorkOrderLine,
} from '@tag/graphql';
import { Observable, of } from 'rxjs';

import { GroupedScheduleDetail } from '@page-timesheet-models/grouped-schedule-detail';
import { ScheduleDetail } from '@stores-models/schedule-detail';
import { TimeSheet } from '@stores-models/timesheet';

import { TsWorkerFunctions } from './ts-worker-functions';

@Injectable({
  providedIn: 'root',
})
export class WorkerService {
  constructor() {}

  generateSchedules(
    emps: Employee[],
    hols: Holiday[],
    vacs: Break[],
    templates: ScheduleDetail[],
    tz: TimezoneOffset[],
    start: Date,
    end: Date
  ): Observable<ScheduleDetail[]> {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(
        new URL('../../shared/workers/ts-schedule-gen.worker', import.meta.url)
      );
      const obs$: Observable<ScheduleDetail[]> = new Observable((observer) => {
        worker.onmessage = ({ data }): void => {
          observer.next(data);
          observer.complete();
        };
      });

      worker.postMessage({ emps, hols, vacs, templates, tz, start, end });
      return obs$;
    } else {
      // IF worker is not supported, we just generate the schedule in the main thread.
      return of(
        TsWorkerFunctions.generateSchedule(
          emps,
          hols,
          vacs,
          templates,
          tz,
          start,
          end
        )
      );
    }
  }

  generateFullWorkSchedule(
    es: ScheduleDetail[],
    tsls: TimeSheet[],
    wos: WorkOrder[],
    wols: WorkOrderLine[],
    pers: Personnel[] = [],
    start: Date,
    end: Date,
    tsHeader: TimesheetHeader[] = [],
    absences: Break[] = [],
    holidays: Holiday[] = [],
    multiSchedule: boolean = false,
    crews: Crew[] = [],
    crewSchedule: CrewSchedule[] = [],
    isTs: boolean = true
  ): Observable<GroupedScheduleDetail[]> {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(
        new URL(
          '../../shared/workers/ts-schedule-full-gen.worker',
          import.meta.url
        )
      );
      const obs$: Observable<GroupedScheduleDetail[]> = new Observable(
        (observer) => {
          worker.onmessage = ({ data }): void => {
            observer.next(data);
            observer.complete();
          };
        }
      );
      worker.postMessage({
        es,
        tsls,
        wos,
        wols,
        pers,
        tsHeader,
        absences,
        holidays,
        start,
        end,
        multiSchedule,
        crews,
        crewSchedule,
        isTs,
      });
      return obs$;
    } else {
      // IF worker is not supported, we just generate the schedule in the main thread.
      return of(
        TsWorkerFunctions.mapCustomEmployeeScheduleGroup(
          es,
          tsls,
          wos,
          wols as any,
          pers,
          tsHeader,
          absences,
          holidays,
          start,
          end,
          multiSchedule,
          crews,
          crewSchedule,
          isTs
        )
      );
    }
  }
}
