// @ts-nocheck
import {
  EmployeeAbsenceSched,
  EmployeeGroupSched,
  EmployeeHolidaySched,
} from '@tag/graphql';
import { differenceInSeconds } from 'date-fns';

/* eslint-disable @typescript-eslint/naming-convention */
import { Break } from './break';

interface _scheduleDetail {
  employeeGroupCode?: string;
  schedulePeriod: Date;
  timeEntryType?: string;
  timeEntryTypeCode?: string;
  scheduledStartDateTime?: Date;
  scheduledEndDateTime?: Date;

  // Used only when schedule is assigned to resource
  employeeNo?: string;
  resourceNo?: string;

  //Only when it is an absence
  absenceCode?: string;
  absenceStatus?: string;
  absenceDocNo?: string;
  absenceType?: string;
  scheduledQuantityHours: number;
}

export class ScheduleDetail implements _scheduleDetail {
  id = crypto.randomUUID();
  employeeGroupCode?: string;
  schedulePeriod = new Date();
  timeEntryType = 'Regular Work';
  timeEntryTypeCode?: string;
  scheduledStartDateTime: Date = new Date();
  scheduledEndDateTime: Date = new Date();
  employeeNo?: string;
  resourceNo?: string;
  absenceCode?: string;
  absenceStatus?: string;
  absenceDocNo?: string;
  absenceType?: string;
  scheduledQuantityHours = 0;

  constructor(
    startDate?: Date,
    endDate?: Date,
    weekDate?: Date,
    resourceNo?: string,
    employeeNo?: string
  ) {
    if (startDate) this.scheduledStartDateTime = startDate;
    if (endDate) this.scheduledEndDateTime = endDate;
    if (weekDate) this.schedulePeriod = weekDate;
    if (resourceNo) this.resourceNo = resourceNo;
    if (employeeNo) this.employeeNo = employeeNo;
  }

  // Convert EmployeeGroupSched to ScheduleDetail
  fromEmployeeGroupSched(employeeGroupSched: EmployeeGroupSched): void {
    const x: _scheduleDetail = {
      employeeGroupCode: employeeGroupSched.employeeGroupCode,
      schedulePeriod: this.schedulePeriod,
      timeEntryType: this.getTimeEntryType(
        employeeGroupSched.dayType || 'Working Day'
      ),
      scheduledStartDateTime: this.scheduledStartDateTime,
      scheduledEndDateTime: this.scheduledEndDateTime,
      scheduledQuantityHours:
        (employeeGroupSched.totalDurationMinutes ?? 0) / 60,
    };

    Object.assign(this, x);
  }

  // Convert fromBreak to ScheduleDetail
  fromBreak(employeeGroupSched: Break): void {
    const x: _scheduleDetail = {
      employeeGroupCode: employeeGroupSched.employeeGroupCode,
      schedulePeriod: this.schedulePeriod,
      timeEntryType: 'Scheduled Break',
      timeEntryTypeCode: employeeGroupSched.breakCode,
      scheduledStartDateTime: this.scheduledStartDateTime,
      scheduledEndDateTime: this.scheduledEndDateTime,
      scheduledQuantityHours: (employeeGroupSched.durationMinutes || 0) / 60,
    };

    Object.assign(this, x);
  }

  fromHoliday(holiday: EmployeeHolidaySched): void {
    if (!holiday.startDateTime || !holiday.endDateTime) return;
    const x: _scheduleDetail = {
      employeeGroupCode: holiday.employeeGroupCode,
      employeeNo: holiday.employeeNo,
      resourceNo: holiday.resourceNo,
      schedulePeriod: this.schedulePeriod,
      timeEntryType: 'Holiday',
      timeEntryTypeCode: holiday.holidayCode,
      scheduledStartDateTime: new Date(holiday.startDateTime),
      scheduledEndDateTime: new Date(holiday.endDateTime),
      scheduledQuantityHours:
        differenceInSeconds(
          new Date(holiday.endDateTime),
          new Date(holiday.startDateTime)
        ) / 3600,
    };

    Object.assign(this, x);
  }

  fromAbsence(abs: EmployeeAbsenceSched): void {
    if (!abs.scheduledStartDateTime || !abs.scheduledEndDateTime) return;
    const x: _scheduleDetail = {
      employeeGroupCode: abs.employeeGroupCode,
      employeeNo: abs.employeeNo,
      resourceNo: abs.resourceNo,
      schedulePeriod: this.schedulePeriod,
      timeEntryType: 'Absence',
      timeEntryTypeCode: abs.absenceCode,
      absenceDocNo: abs.absenceNo,
      absenceCode: abs.absenceCode,
      absenceStatus: abs.status,
      scheduledStartDateTime: abs.scheduledStartDateTime,
      scheduledEndDateTime: abs.scheduledEndDateTime,
      scheduledQuantityHours: abs.hours ?? 0,
    };

    Object.assign(this, x);
  }

  fromScheduleDetail(scheduleDetail: _scheduleDetail): void {
    Object.assign(this, scheduleDetail);
  }

  adjustTimezone(utcOffSet: number): void {
    this.scheduledStartDateTime = new Date(
      this.scheduledStartDateTime.toLocaleString('en-US', { timeZone: 'UTC' })
    );
    this.scheduledEndDateTime = new Date(
      this.scheduledEndDateTime.toLocaleString('en-US', { timeZone: 'UTC' })
    );
    this.schedulePeriod = new Date(
      this.schedulePeriod.toLocaleString('en-US', { timeZone: 'UTC' })
    );
    this.scheduledStartDateTime.setMinutes(
      this.scheduledStartDateTime.getMinutes() + utcOffSet
    );
    this.scheduledEndDateTime.setMinutes(
      this.scheduledEndDateTime.getMinutes() + utcOffSet
    );
    this.schedulePeriod.setMinutes(
      this.schedulePeriod.getMinutes() + utcOffSet
    );
  }

  private getTimeEntryType(code: string): string {
    switch (code) {
      case 'Working Day':
        return 'Regular Work';
      default:
        return code;
        break;
    }
  }
}
