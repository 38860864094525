export enum FormOptions {
  default = 'defaultFormKey',
  createCrew = 'createCrewKey',
  createTask = 'createTaskKey',
  createWorkOrder = 'createWorkOrderFormKey',
  createWorkOrderFromRequest = 'createWorkOrderFromRequestKey',
  reportInspection = 'reportInspectionFormKey',
  createRequest = 'createRequestFormKey',
  createEquipmentFromScratch = 'createEquipmentFromScratchKey',
  createEquipmentFromTemplate = 'createEquipmentFromTemplateKey',
  cloneEquipment = 'cloneEquipmentKey',
  completeWorkOrder = 'completeWorkOrderFormKey',
  createSubassembly = 'createSubassemblyFormKey',
  createConsumable = 'createConsumableKey',
  createItem = 'createItemKey',
  createPersonnel = 'createPersonnelKey',
  createProcurement = 'createProcurementKey',
  editCrew = 'editCrewKey',
  editTask = 'editTaskKey',
  editWorkOrder = 'editWorkOrderFormKey',
  editPlannedWorkOrder = 'editPlannedWorkOrderFormKey',
  editWorkOrders = 'editWorkOrdersFormKey',
  editRequest = 'editRequestFormKey',
  editEquipment = 'editEquipmentKey',
  editConsumable = 'editConsumableKey',
  editItem = 'editItemKey',
  editPersonnel = 'editPersonnelKey',
  logMeterEntry = 'logMeterEntryFormKey',
  reportMaintenance = 'reportMaintenanceFormKey',
  pickUp = 'purchaseSummaryKey',
  createPurchaseOrder = 'createPurchaseKey',
  editPurchaseOrder = 'editPurchaseKey',
  type = 'typeKey',
  reviewWorkOrder = 'reviewWorkOrderFormKey',
  reviewRequest = 'reviewRequestFormKey',
  reviewTimesheet = 'reviewTimesheet',
  foremanDispatch = 'foremanDispatchKey',
  formDataEntry = 'formDataEntry',
}
