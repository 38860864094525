// @ts-nocheck
import {
  Crew,
  CrewSchedule,
  Employee,
  EmployeeAbsenceSched,
  EmployeeHolidaySched,
  EmployeeList,
  Personnel,
  TimeZoneOffset,
  TimesheetHeader,
  WorkOrder,
} from '@tag/graphql';
import { previousDay, startOfDay } from 'date-fns';

import { BryTimeRange } from '@dispatch-models/bry-time-range';
import { GroupedScheduleDetail } from '@page-timesheet-models/grouped-schedule-detail';
import { TimeSheet } from '@stores-models/timesheet';

import { ScheduleDetail } from '../../stores/models/schedule-detail';
import { WorkOrderLine } from '@tag/graphql';

export class TsWorkerFunctions {
  static generateSchedule(
    emps: EmployeeList[],
    hols: EmployeeHolidaySched[],
    vacs: EmployeeAbsenceSched[],
    templates: ScheduleDetail[],
    tz: TimeZoneOffset[],
    start: Date,
    end: Date
  ): ScheduleDetail[] {
    const generatedSchedule: ScheduleDetail[] = [];
    emps.forEach((emp) => {
      if (!emp.employeeNo) return;

      const template = templates.filter(
        (x) => x.employeeGroupCode === emp.employeeGroupCode
      );
      const fHols = [...hols].filter((x) => x.employeeNo === emp.employeeNo);
      const fVacs = [...vacs].filter((x) => x.employeeNo === emp.employeeNo);
      const fTz = [...tz].filter((x) => x.time_Zone_Code === emp.timeZone);
      const generatedEmployeeSchedule = this.generateSingleEmployeeSchedule(
        emp,
        template,
        fHols,
        fVacs,
        start,
        end,
        fTz
      );
      generatedSchedule.push(...generatedEmployeeSchedule);
    });

    return generatedSchedule;
  }

  /**
   * Generates employee schedule
   *
   * @param template
   * @param hols
   * @param vacs
   * @param start
   * @param end
   * @returns employee schedule
   */
  static generateSingleEmployeeSchedule(
    emp: Employee,
    template: ScheduleDetail[],
    hols: EmployeeHolidaySched[],
    vacs: EmployeeAbsenceSched[],
    start: Date,
    end: Date,
    tz?: TimeZoneOffset[]
  ): ScheduleDetail[] {
    let schedule: ScheduleDetail[] = [];
    const date = new Date(start);
    date.setHours(0, 0, 0, 0);

    const employeeNo = emp.employeeNo;
    const resourceNo = emp.resourceNo;

    // TODO TA - Handle time zone for start and end of schedule (no need for Vacs and hols)
    while (date.getTime() <= end.getTime()) {
      const daySch = template
        .filter((t) => t.scheduledStartDateTime.getDay() === date.getDay())
        .map((t) => {
          const periodDay = t.schedulePeriod.getDay();

          const diffT = new Date(t.scheduledStartDateTime);
          diffT.setHours(0, 0, 0, 0);

          const diff = date.getTime() - diffT.getTime();

          const x = new ScheduleDetail();
          x.fromScheduleDetail({
            ...t,
            scheduledStartDateTime: new Date(
              t.scheduledStartDateTime.getTime() + diff
            ),
            scheduledEndDateTime: new Date(
              t.scheduledEndDateTime.getTime() + diff
            ),
            schedulePeriod:
              new Date(diffT.getTime() + diff).getDay() === periodDay
                ? new Date(diffT.getTime() + diff)
                : previousDay(new Date(diffT.getTime() + diff), periodDay),
            employeeNo,
            resourceNo,
          });
          return x;
        });
      schedule.push(...daySch);
      date.setDate(date.getDate() + 1);
    }

    hols.forEach((h) => {
      if (!h.startDateTime) return;
      const hStart = startOfDay(new Date(h.startDateTime));

      const weekDate = schedule.find(
        (s) =>
          startOfDay(s.scheduledStartDateTime).getTime() === hStart.getTime()
      )?.schedulePeriod;

      if (!weekDate) return;

      schedule = schedule.filter(
        (s) =>
          startOfDay(s.scheduledStartDateTime).getTime() !== hStart.getTime()
      );
      const x = new ScheduleDetail(undefined, undefined, weekDate);
      x.fromHoliday(h);
      schedule.push(x);
    });

    vacs.forEach((v) => {
      if (!v.scheduledStartDateTime || !v.scheduledEndDateTime) return;
      const vStart = startOfDay(v.scheduledStartDateTime);

      const matchingSchedule = schedule.filter(
        (s) =>
          startOfDay(s.scheduledStartDateTime).getTime() === vStart.getTime() &&
          ['Regular Work', 'Scheduled Break'].includes(s.timeEntryType || '')
      );
      if (!matchingSchedule.length) return;

      const absence = new ScheduleDetail(
        undefined,
        undefined,
        matchingSchedule[0].schedulePeriod
      );
      absence.fromAbsence(v);

      const mergedSchedule: ScheduleDetail[] = [absence];

      matchingSchedule
        .sort(
          (a, b) =>
            a.scheduledStartDateTime.getTime() -
            b.scheduledStartDateTime.getTime()
        )
        .forEach((s) => {
          if (
            absence.scheduledStartDateTime.getTime() >
              s.scheduledStartDateTime.getTime() &&
            s.scheduledEndDateTime.getTime() >
              absence.scheduledStartDateTime.getTime()
          ) {
            s.scheduledEndDateTime = absence.scheduledStartDateTime;
            mergedSchedule.push(s);

            const additionalSchedule = new ScheduleDetail(
              undefined,
              undefined,
              matchingSchedule[0].schedulePeriod
            );
            additionalSchedule.fromScheduleDetail({
              ...s,
              scheduledStartDateTime: absence.scheduledEndDateTime,
            });
            mergedSchedule.push(additionalSchedule);
          } else if (
            s.scheduledStartDateTime.getTime() >=
              absence.scheduledStartDateTime.getTime() &&
            s.scheduledEndDateTime.getTime() <=
              absence.scheduledEndDateTime.getTime()
          ) {
            return;
          } else if (
            s.scheduledEndDateTime.getTime() >
            absence.scheduledEndDateTime.getTime()
          ) {
            s.scheduledStartDateTime = absence.scheduledEndDateTime;
            mergedSchedule.push(s);
          } else {
            mergedSchedule.push(s);
          }
        });

      schedule = schedule.filter(
        (s) =>
          !mergedSchedule.find(
            (m) =>
              startOfDay(m.scheduledStartDateTime).getTime() ===
              startOfDay(s.scheduledStartDateTime).getTime()
          )
      );
      schedule.push(...mergedSchedule);
    });

    return schedule;
  }

  /**
   * Maps dyn employee schedule group, it will generate schedule based on Employee Schedule
   *
   * @param es
   * @param tsls
   * @param wos
   * @param wols
   * @param [pers]
   * @param [multiSchedule]
   * @returns dyn employee schedule group
   */
  static mapCustomEmployeeScheduleGroup(
    es: ScheduleDetail[],
    tsls: TimeSheet[],
    wos: WorkOrder[],
    wols: WorkOrderLine[],
    pers: Personnel[] = [],
    tsHeaders: TimesheetHeader[] = [],
    absences: EmployeeAbsenceSched[] = [],
    holidays: EmployeeHolidaySched[] = [],
    start: Date,
    end: Date,
    multiSchedule: boolean = false,
    crews: Crew[] = [],
    crewSchedule: CrewSchedule[] = [],
    isTs: boolean = true
  ): GroupedScheduleDetail[] {
    const schedule = es.filter((value) => {
      const sDate = value.scheduledStartDateTime;
      const eDate = value.scheduledEndDateTime;

      if (!sDate || !eDate) return false;

      return (
        sDate.getTime() >= start.getTime() && sDate.getTime() <= end.getTime()
      );
    });

    const employeeSchedule: GroupedScheduleDetail = {
      weekYear: '',
      start,
      end,
      absences: absences.filter((abs) => {
        if (!abs.scheduledStartDateTime || !abs.scheduledEndDateTime)
          return false;
        const sDate = new Date(abs.scheduledStartDateTime);
        const eDate = new Date(abs.scheduledEndDateTime);

        if (!sDate || !eDate) return false;

        return (
          sDate.getTime() >= start.getTime() && sDate.getTime() <= end.getTime()
        );
      }),
      holidays: holidays.filter((hol) => {
        if (!hol.startDateTime || !hol.endDateTime) return false;
        const sDate = new Date(hol.startDateTime);
        const eDate = new Date(hol.endDateTime);

        if (!sDate || !eDate) return false;

        return (
          sDate.getTime() >= start.getTime() && sDate.getTime() <= end.getTime()
        );
      }),
      scheduleLines: schedule,
      tsls: [],
      wos: [],
      wols: [],
      count: schedule.length,
      multiSchedule,
      pers,
      tsHeader: tsHeaders,
      crews,
      crewSchedule,
      status: 'open',
    };

    const matchingTsls = tsls.filter(
      (tsl) =>
        tsl.starting_Datetime.getTime() >= employeeSchedule.start.getTime() &&
        tsl.starting_Datetime.getTime() <= employeeSchedule.end.getTime()
    );
    employeeSchedule.tsls = matchingTsls;

    const matchingWos: WorkOrder[] = [];
    const matchingWols: WorkOrderLine[] = [];

    matchingTsls.forEach((tsl) => {
      const matchingWo = wos.find((wo) => tsl.source_Work_Order_No === wo.no);
      if (matchingWo && !matchingWos.includes(matchingWo))
        matchingWos.push(matchingWo);
    });

    matchingTsls.forEach((tsl) => {
      const matchingWol = wols.find(
        (wol) =>
          tsl.source_Work_Order_No + tsl.source_Work_Order_Line ===
          wol.work_Order_No + wol.line_No
      );
      if (matchingWol && !matchingWols.includes(matchingWol))
        matchingWols.push(matchingWol);
    });
    employeeSchedule.wos = isTs ? matchingWos : wos;
    // @ts-ignore
    employeeSchedule.wols = isTs ? matchingWols : wols;

    employeeSchedule.pers = pers;

    employeeSchedule.multiSchedule = multiSchedule;

    employeeSchedule.status = this.getTslsStatus(employeeSchedule.tsls);

    return [employeeSchedule];
  }

  /**
   * Gets status
   *
   * @param esl
   * @returns status
   */
  static getTslsStatus(
    tsls: TimeSheet[]
  ):
    | 'pending'
    | 'approved'
    | 'returned'
    | 'posted'
    | 'open'
    | 'hrPosted'
    | 'hrApproved' {
    if (tsls.length === 0) return 'open';

    const hrPosted = tsls.every((tsl) => tsl.approval_Status === 'hrPosted');
    if (hrPosted) return 'hrPosted';

    const hrApproved = tsls.every(
      (tsl) => tsl.approval_Status === 'hrApproved'
    );
    if (hrApproved) return 'hrApproved';

    const posted = tsls.every((tsl) => tsl.posted);
    if (posted) return 'posted';

    const pending = tsls.every((tsl) => tsl.approval_Status === 'Pending');
    if (pending) return 'pending';

    const returned = tsls.some((tsl) => tsl.approval_Status === 'Returned');
    if (returned) return 'returned';

    const open = tsls.some((tsl) => tsl.approval_Status === 'Open');
    if (open) return 'open';

    const approved = tsls.every(
      (tsl) => tsl.approval_Status === 'Approved' || tsl.posted
    );
    if (approved) return 'approved';

    return 'pending';
  }

  /**
   * Maps resource time ranges
   *
   * @param employeeSchedule
   * @param techs
   * @returns resource time ranges
   */
  static mapResourceTimeRanges(
    employeeSchedule: ScheduleDetail[],
    techs: Personnel[],
    start: Date,
    end: Date
  ): BryTimeRange[] {
    const timeRanges: BryTimeRange[] = [];

    employeeSchedule.forEach((scheduleEvent: ScheduleDetail, i) => {
      if (
        scheduleEvent.scheduledStartDateTime.getTime() + 86400000 <
          start.getTime() ||
        scheduleEvent.scheduledStartDateTime.getTime() - 86400000 >
          end.getTime()
      )
        return;

      // if (!['Regular Work', 'No Duty'].includes(scheduleEvent.timeEntryType)) {
      const assignedtech = techs.find(
        (tech) => tech.employee_No === scheduleEvent.employeeNo
      );
      const startDate = scheduleEvent.scheduledStartDateTime;
      const endDate = scheduleEvent.scheduledEndDateTime;
      const color = this.getColor(scheduleEvent.timeEntryType || '');
      const name =
        scheduleEvent.timeEntryType === 'Regular Work'
          ? ''
          : scheduleEvent.timeEntryType;

      if (startDate?.getTime() === endDate?.getTime())
        endDate?.setDate(endDate.getDate() + 1);

      if (assignedtech && startDate && endDate) {
        if (!['Regular Work'].includes(scheduleEvent.timeEntryType)) {
          timeRanges.push({
            id: i + 1,
            resourceId: assignedtech.no || '',
            startDate,
            endDate,
            name: ['Scheduled Break', 'No Duty'].includes(
              scheduleEvent.timeEntryType
            )
              ? ''
              : name || '',
            timeRangeColor: color,
            isWorking: false,
          });
        }
        // Fill the gaps between every time range with a not working time range
        if (i > 0) {
          const previousEndDate = employeeSchedule[i - 1].scheduledEndDateTime;
          if (
            previousEndDate &&
            startDate &&
            previousEndDate.getTime() < startDate.getTime() &&
            employeeSchedule[i - 1].resourceNo === assignedtech.no
          ) {
            timeRanges.push({
              id: Math.random() * 1000000,
              resourceId: assignedtech.no || '',
              startDate: previousEndDate,
              endDate: startDate,
              name: '',
              timeRangeColor: 'gray',
              isWorking: false,
            });
          }
        }

        const firstTimeRange = timeRanges.find(
          (timeRange) => timeRange.resourceId === assignedtech.no
        );

        // Fill the gaps between the first time range and the start date with a not working time range
        if (!firstTimeRange) {
          timeRanges.push({
            id: Math.random() * 1000000,
            resourceId: assignedtech.no || '',
            startDate: start,
            endDate: startDate,
            name: '',
            timeRangeColor: 'gray',
            isWorking: false,
          });
        }

        // Fill the gaps between the last time range and the end date with a not working time range
        const nextTimeRange = employeeSchedule[i + 1];

        if (!nextTimeRange || nextTimeRange.resourceNo !== assignedtech.no) {
          timeRanges.push({
            id: Math.random() * 1000000,
            resourceId: assignedtech.no || '',
            startDate: endDate,
            endDate: end,
            name: '',
            timeRangeColor: 'gray',
            isWorking: false,
          });
        }
      }
    });

    return timeRanges;
  }

  /**
   * Gets color
   *
   * @param timeEntryType
   * @returns color
   */
  static getColor(timeEntryType: string): string {
    let color: string;
    switch (timeEntryType) {
      case 'Scheduled Break':
        color = 'orange';
        break;
      case 'Vacation':
        color = 'red';
        break;
      case 'Regular Work':
        color = 'teal';
        break;
      case 'No Duty':
        color = 'gray';
        break;
      default:
        color = 'blue';
        break;
    }
    return color;
  }
}
