import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { config } from '@tag/graphql';
import type { FinishedWorkOrderLine, GraphQLParams } from '@tag/graphql';

export interface Response {
  finishedWorkOrderLines: {
    items: FinishedWorkOrderLine[];
    totalCount: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FinishedWorkOrderLinesGQL extends Query<
  Response,
  GraphQLParams<'finishedWorkOrderLine'>
> {
  document = gql(config.finishedWorkOrderLine.query);
}
