// @ts-nocheck
import {
  BatchEmployeeAbsenceRequestEdit,
  Employee,
  EmployeeAbsenceRequestEdit,
  EmployeeAbsenceRequestPost,
  TimesheetHeaderPost,
  TimesheetJournal,
} from '@tag/graphql';

/**
 * Get employee schedule
 *
 * @param filter OData filter
 */
export class GetEmployeeSchedule {
  static readonly type = '[TA] Get Employee Schedule';

  constructor(public start: Date, public end: Date) {}
}

/**
 * Get employee schedule
 *
 * @param filter OData filter
 */
export class GetSchedule {
  static readonly type = '[TA] Get Schedule';

  constructor(
    public start: Date,
    public end: Date,
    public bustCache?: boolean
  ) {}
}

/**
 * Get employee schedule
 *
 * @param bustCache bust the state cache
 */
export class GetScheduleTemplate {
  static readonly type = '[TA] Get Schedule Template';

  constructor(public bustCache?: boolean) {}
}

/**
 * Get banks of hours
 */
export class GetBanksOfHours {
  static readonly type = '[TA] Get Banks of hours';

  constructor() {}
}

/**
 * Get ta employees
 */
export class GetTaEmployees {
  static readonly type = '[TA] Get Ta Employees';

  constructor(public bustCache?: boolean) {}
}

/**
 * Add abscence request
 */
export class AddAbsenceRequest {
  static readonly type = '[TA] Add Absence Request';

  constructor(public payload: EmployeeAbsenceRequestPost) {}
}

/**
 * Add ta employee
 */
export class AddTaEmployee {
  static readonly type = '[TA] Add TaEmployee';

  constructor(public payload: Employee) {}
}

/**
 * Update absence request
 */
export class UpdateAbsenceRequest {
  static readonly type = '[TA] Update Absence Request';

  constructor(public payload: EmployeeAbsenceRequestEdit) {}
}

/**
 * Post journal lines
 */
export class PostJournalLines {
  static readonly type = '[TA] Post Journal Lines';

  constructor(
    public payload: TimesheetJournal[],
    public groupNo: string,
    public postingDate: Date
  ) {}
}

/**
 * Add time sheet header
 */
export class AddTimeSheetHeader {
  static readonly type = '[TA] Add TimeSheet Header';

  constructor(public payload: TimesheetHeaderPost) {}
}

/**
 * Add time sheet header
 */
export class GetTimeSheetHeaders {
  static readonly type = '[TA] Get TimeSheet Headers';

  constructor() {}
}

/**
 * Add tech time sheet header
 */
export class GetTechTimeSheetHeaders {
  static readonly type = '[TA] Get TimeSheet Header';

  constructor() {}
}

/**
 * Batch update absences
 */
export class BatchUpdateAbsenceStatus {
  static readonly type = '[TA] Batch Update Absences';

  constructor(public payload: BatchEmployeeAbsenceRequestEdit) {}
}
